<template>
  <b-sidebar
    id="vendor-details-sidebar"
    ref="sidebar"
    width="50%"
    bg-variant="white"
    right
    backdrop
    no-header
    @hidden="resetData()"
    @shown="getVendorData()"
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ isObjectEmpty(vendorInfo) ? 'Edit Vendor': 'Add Vendor' }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <!-- form -->
      <validation-observer ref="vendor-form">
        <b-form
          class="p-1"
          @submit.prevent
        >
          <b-row>
            <!-- Name -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required"
              >
                <b-form-group
                  label="Name"
                  label-for="Name"
                >
                  <b-form-input
                    id="Name"
                    v-model="vendorDetail.name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Email -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="email"
              >
                <b-form-group
                  label="Email"
                  label-for="email"
                >
                  <b-form-input
                    id="email"
                    v-model="vendorDetail.email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Phone -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="phone"
                rules="phone"
              >
                <b-form-group
                  label="Phone"
                  label-for="phone"
                >
                  <b-input-group
                    prepend="+880"
                  >
                    <b-form-input
                      id="phone"
                      v-model="vendorDetail.phone_number"
                      type="number"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Submit -->
            <b-col cols="12">
              <b-button
                type="submit"
                variant="primary"
                class="mr-1"
                :disabled="isBtnLoading"
                @click="validateForm"
              >
                <b-spinner
                  v-if="isBtnLoading"
                  small
                />
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email, phone,
} from '@validations'
import api from '@/libs/api'
import helper from '@/mixins/helper'

export default {
  name: 'VendorForm',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [helper],
  props: {
    vendorInfo: {
      type: Object,
    },
  },
  data() {
    return {
      isBtnLoading: false,
      vendorDetail: {},
      required,
      email,
      phone,
    }
  },
  methods: {
    createOrUpdateVendor() {
      this.isBtnLoading = true
      let url = 'tour/admin_services/vendor_create/'
      let apiResponse
      const vendorExists = this.vendorDetail.id

      if (vendorExists) {
        url = `tour/admin_services/vendor/${this.vendorInfo.id}/update/`
        apiResponse = api.updateData(url, true, this.vendorDetail)
      } else {
        apiResponse = api.postData(url, true, this.vendorDetail)
      }
      apiResponse.then(response => {
        if (response.data.status) {
          this.notificationMessage('success', 'CheckIcon', 'Success', `vendor ${vendorExists ? 'updated' : 'created'} successfully`)
          this.$emit('getVendorDetails')
        } else {
          this.notificationMessage('danger', 'Xicon', 'Error', JSON.stringify(response.data.error.message))
        }
      }).catch(() => {
        this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, something went wrong!')
      }).finally(() => {
        this.isBtnLoading = false
      })
    },
    resetData() {
      this.$emit('resetData')
    },
    getVendorData() {
      this.vendorDetail = JSON.parse(JSON.stringify(this.vendorInfo))
    },
    validateForm() {
      this.$refs['vendor-form'].validate().then(success => {
        if (success) {
          this.createOrUpdateVendor()
        } else {
          this.notificationMessage('warning', 'XIcon', 'Error', 'Please fill up all required fields')
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/style.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
