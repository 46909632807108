<template>
  <div
    v-if="listData.length > 0"
    class="mx-2 mb-2"
  >
    <b-row>

      <b-col
        cols="12"
        sm="6"
        class="d-flex align-items-center justify-content-center justify-content-sm-start"
      >
        <span class="text-muted">
          Showing {{ (currentPage - 1) * 10 + 1 }} to {{ (limit) * (currentPage - 1) + listData.length }} of {{ total }} entries
        </span>
      </b-col>
      <!-- Pagination -->
      <b-col
        cols="12"
        sm="6"
        class="d-flex align-items-center justify-content-center justify-content-sm-end"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="total"
          :per-page="limit"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
          @input="paginate()"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>

      </b-col>

    </b-row>
  </div>
</template>

<script>
export default {
  name: 'TablePagination',
  props: {
    listData: {
      type: Array,
    },
    total: {
      type: Number,
    },
    limit: {
      type: Number,
    },
    offset: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      currentPage: 1,
    }
  },
  watch: {
    offset(val) {
      if (val === 0) {
        this.currentPage = 1
      }
    },
  },
  methods: {
    paginate() {
      this.$emit('paginate', this.currentPage)
    },
    resetCurrentPage() {
      this.currentPage = 1
    },
  },
}
</script>

<style scoped>

</style>
